import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, from, merge, Observable, of} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {AuthChangeEvent, Session, User} from "@supabase/supabase-js";
import {SupabaseClient} from "packages/supabase/SupabaseClient";
import {Language} from "app/i18n/types";
import {Translator} from "app/i18n/translator";
import {Router} from "@angular/router";
import {WINDOW} from "@ng-web-apis/common";

export type Profile = {
  id: number
  userId: string
  name: string | null
  email: string
  avatar: string
  roles: Array<string>
  lang?: Language
  active: boolean
}

export class Passport {
  private profile: Profile | null = null;

  constructor(private user: User | null) {
  }

  getUser(): User {
    if (this.user === null) {
      throw new Error('Called user before check of auth state')
    }

    return this.user
  }

  isAnon(): boolean {
    return this.user === null
  }

  setProfile(profile: Profile): void {
    this.profile = profile
  }

  isActive(): boolean {
    return !!this.profile && this.profile.active
  }
}


@Injectable(
  {providedIn: 'root'}
)
export class Authenticator {
  private _authState$ = new BehaviorSubject<Passport>(new Passport(null))
  private _prevToken: string | null | undefined = null

  private supabase = inject(SupabaseClient).getClient
  private translator: Translator = inject(Translator)
  private router: Router = inject(Router)
  private window = inject(WINDOW)

  private _profile$ = new BehaviorSubject<Profile | null>(null)

  /**
   * @deprecated
   */
  public sessionUser$: Observable<User | null> = from(this.supabase.auth.getUser())
    .pipe(
      map(res => {
        if (!res.error && res.data.user) {
          return res.data.user
        }

        return null
      })
    )

  constructor() {
    this.supabase.auth
      .onAuthStateChange((ch, session) => {
        if (ch === 'SIGNED_OUT') {
          this.translator.logout()
          this._profile$.next(null)
        }

        if (this._prevToken === session?.provider_token) {
          return
        }

        this._prevToken = session?.provider_token

        this._authState$.next(new Passport(session?.user || null))
      })
  }

  passport(): Observable<Passport> {
    return merge(
      this._authState$.pipe(
        map(p => <Passport>p),
        switchMap(p => this.enrichProfile(p)),
      ),
      from(this.supabase.auth.getSession())
        .pipe(
          filter(res => res.data.session?.provider_token !== this._prevToken),
          map(res => {
            if (!res.error && !!res.data.session?.user) {
              return new Passport(res.data.session?.user)
            }

            return new Passport(null)
          }),
          switchMap(p => this.enrichProfile(p))
        )
    )
  }

  private enrichProfile(passport: Passport): Observable<Passport> {
    if (passport.isAnon()) {
      return of(passport)
    }

    return from(
      this.supabase
        .from('profiles')
        .select(`id, userId, name, email, avatar, lang, roles:profile_roles(roles), active`)
        .eq('userId', passport.getUser().id)
        .single()
    )
      .pipe(
        // @ts-ignore
        map(res => res.data as Profile),
        tap(profile => {
          if (profile) {
            this._profile$.next(profile)
            profile.lang && this.translator.changeLang(profile.lang, true)
          }

          this.enrichName(profile)
          passport.setProfile(profile)
        }),
        map(() => passport)
      )
  }


  profile(): Observable<Profile | null> {
    return this._profile$
  }

  updateProfile(id: number, partialProfile: Partial<Profile>): Observable<Profile> {
    return from(
      this.supabase
        .from('profiles')
        .update(partialProfile)
        .eq('id', id)
        .select(`id, userId, name, email, lang, avatar, roles:profile_roles(roles), active`)
    )
      .pipe(
        tap((res) => {
          if (res.data) {
            // @ts-ignore
            const prof = res.data as Profile
            this._profile$.next(prof)
            prof.lang && this.translator.changeLang(prof.lang, true)
          }
        }),
        map((res) => {
          // @ts-ignore
          const prof = res.data as Profile
          this.enrichName(prof)

          return prof
        }),
      )
  }

  private enrichName(profile: Profile) {
    // enrich name from email
    if (!profile.name) {
      profile.name = String(profile.email).split('@')[0]
    }
  }

  /**
   * @deprecated
   */
  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase.auth.onAuthStateChange(callback)
  }

  signIn(email: string, password: string) {
    return from(
      this.supabase.auth.signInWithPassword({
        email: email,
        password: password
      })
    )
  }

  signInWithOAuth(provider: 'google' | 'github') {
    const url = this.router.createUrlTree(['redirect', provider])
    this.window.open(url.toString(), '_blank')
  }

  signInWithMagicLink(email: string) {
    return from(this.supabase.auth.signInWithOtp({
      email: email
    }))
  }

  signUp(email: string, password: string) {
    return from(this.supabase.auth.signUp({
      email: email,
      password: password,
    }))
  }

  signOut() {
    return from(this.supabase.auth.signOut())
  }
}
