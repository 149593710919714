import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

const IS_NIGHT_THEME = 'isDark'

@Injectable({
  providedIn: 'root',
})
export class ThemeToggle {
  public isNightTheme$: BehaviorSubject<boolean> = this.loadTheme();

  setNight(enabled: boolean) {
    localStorage.setItem(IS_NIGHT_THEME, enabled ? '1' : '0')
    this.isNightTheme$.next(enabled)
  }

  loadTheme() {
    const theme = localStorage.getItem(IS_NIGHT_THEME);
    if (!theme) {
      localStorage.setItem(IS_NIGHT_THEME, '0')
      return new BehaviorSubject(false)
    }

    return new BehaviorSubject(theme === '1')
  }
}
