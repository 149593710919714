export class Changes <T> {
  constructor(
    public value: T,
    public changedFields: (keyof T)[]
  ) {
  }

  public isChangedFields(...names: (keyof T)[]): boolean {
    return names.filter(n => this.changedFields.indexOf(n) !== -1).length > 0
  }
}

export const buildChangesWithFirstValue = <T>(firstObj: T, secondObj: T): Changes<T> => {
  return new Changes(firstObj, changedFields<T>(firstObj, secondObj))
}

export const buildChangesWithSecondValue = <T>(firstObj: T, secondObj: T): Changes<T> => {
  return new Changes(secondObj, changedFields<T>(firstObj, secondObj))
}

const changedFields = <T>(firstObj: T, secondObj: T): (keyof T)[] => {
  const fields: (keyof T)[] = []

  for (const key in firstObj) {
    if (firstObj[key] !== secondObj[key]) {
      fields.push(key)
    }
  }

  return fields;
}
