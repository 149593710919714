import { InjectionToken, Provider } from '@angular/core';
import { CodiewChunkProvider, ICodiewChunksProvider } from './codiew-chunk.provider';
import {PlaylistProvider} from "./playlist.provider";
import {PlaylistTreeBuilder} from "../playlist/playlist-tree.builder";

export const RECORD_EVENTS_PROVIDER_TOKEN = new InjectionToken<ICodiewChunksProvider>('RecordEventsProvider')
export const provideRecordEventsProvider = (): Provider => {
  return {
    provide: RECORD_EVENTS_PROVIDER_TOKEN,
    useClass: CodiewChunkProvider,
  }
}

export const PLAYLIST_PROVIDER_PROVIDER = new InjectionToken<PlaylistProvider>('PlaylistProvider')
export const providePlaylistProvider = (): Provider[] => {
  return [
    {
      provide: PLAYLIST_PROVIDER_PROVIDER,
      useClass: PlaylistProvider,
    },
    PlaylistTreeBuilder,
  ]
}

