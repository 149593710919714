import {Injectable} from "@angular/core";
import {fromEvent, merge, Observable, of, Subject} from "rxjs";
import {map, startWith, switchMap, withLatestFrom} from "rxjs/operators";

interface NetworkMessage {
  onlineStatus: boolean
  type: 'network' | 'backend'
  message: string
}

@Injectable({
  providedIn: 'root'
})
export class NetworkMonitor {
  private serverStatus$ = new Subject<NetworkMessage>()

  // @ts-ignore
  private networkStatus$: Observable<NetworkMessage> = merge(
    merge(
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(
      startWith({onlineStatus: navigator.onLine, type: 'network', message: 'network ping'}),
      map(() => {
        return {onlineStatus: navigator.onLine, type: 'network', message: 'network ping'}
      })
    ),
    this.serverStatus$
  )

  public totalStatus$: Observable<NetworkMessage> = merge(
    this.networkStatus$,
    this.serverStatus$
  )

  registerServerStatus(code: number, message: string) {
    this.serverStatus$.next({
      onlineStatus: code < 400,
      type: 'backend',
      message: message,
    })
  }
}
