import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {filter, takeUntil} from "rxjs/operators";
import {TuiDialogService, TuiRootModule} from "@taiga-ui/core";
import {TuiDestroyService} from "@taiga-ui/cdk";
import {NetworkMonitor} from "packages/network-monitoring/network.monitor";
import {BehaviorSubject, Observable} from "rxjs";
import {DialogBackgroundFilter} from "packages/dialog-bf-filter/dialog.background-filter";
import {Authenticator} from "auth/authenticator";
import {WINDOW} from "@ng-web-apis/common";
import {NightComponent} from "app/night/NightComponent";
import {CommonModule} from "@angular/common";
import {ScrollableComponent} from 'app/scrollable/scrollable';
import {PortalHost} from "../packages/portals/portal-host.component";
import {PageComponent} from "./page/page.component";
import {CODE_SETTINGS_PROVIDER} from "../codiew/settings/types";
import {WebWorkerman} from "./web-workers/web.workerman";

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NightComponent,
    RouterOutlet,
    TuiRootModule,
    ScrollableComponent,
    PortalHost,
    PageComponent,
  ],
  providers: [
    TuiDestroyService,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  public bgBlur$: Observable<boolean> = inject(DialogBackgroundFilter).blurCss$
  private settingsProvider = inject(CODE_SETTINGS_PROVIDER);
  private webWorkerman = inject(WebWorkerman);

  isNightTheme$ = new BehaviorSubject(false)
  private authenticator: Authenticator = inject(Authenticator)
  private window: Window = inject(WINDOW)
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute)

  scrollable$ = new BehaviorSubject(true)

  constructor(
    private _r: Router,
    protected router: Router,
    @Inject(Injector) private readonly injector: Injector,
    private networker: NetworkMonitor,
    @Inject(TuiDialogService) private dialogs: TuiDialogService,
    @Inject(TuiDestroyService) private readonly destroy$: TuiDestroyService,
  ) {
    this.settingsProvider.settings$.subscribe(stgs => {
      this.isNightTheme$.next(stgs.isNightTheme)
    })
  }

  setScrollable(enable: boolean) {
    this.scrollable$.next(enable)
  }

  ngOnInit() {
    this.webWorkerman.register$()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe()

    this.authenticator.authChanges((ch) => {
      this.authenticator.sessionUser$
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(u => {
          if (ch === 'SIGNED_OUT' && u !== null) {
            this.window.location.reload()
          }
        })
    })

    this.router.events.pipe(
      filter(ev => ev instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe()
  }

  ngOnDestroy(): void {
    this.webWorkerman.terminate()
  }
}
