import {Directive, Injectable, Input} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DialogBackgroundFilter {
  public blurCss$ = new BehaviorSubject(false)
}
