import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {
  DEFAULT_SETTINGS,
  EditorSettings, filterStorageEditorSettings,
  NotStoredSettingsKeys,
  SettingsProvider,
  StorageEditorSettings
} from "./types";
import {buildChangesWithFirstValue, Changes} from "packages/utls/changedFields";

const SETTINGS_NAME = 'editor_settings'


@Injectable()
export class PlaySettingsProvider implements SettingsProvider {

  public settings$: BehaviorSubject<EditorSettings> = this.load();
  public settingsChanges$ = new Subject<Changes<EditorSettings>>();

  public save(settings: Partial<EditorSettings>) {
    const oldSettings = this.settings$.value
    this.settings$.next({...oldSettings, ...settings})

    const values: StorageEditorSettings = filterStorageEditorSettings(this.settings$.value, NotStoredSettingsKeys)
    localStorage.setItem(SETTINGS_NAME, JSON.stringify(values))

    this.settingsChanges$.next(buildChangesWithFirstValue(this.settings$.value, oldSettings))
  }

  private load(): BehaviorSubject<EditorSettings> {
    const data = localStorage.getItem(SETTINGS_NAME);
    if (!data || data === '') {
      return new BehaviorSubject(DEFAULT_SETTINGS)
    }

    const settings: StorageEditorSettings = JSON.parse(data)
    if (!settings) {
      return new BehaviorSubject(DEFAULT_SETTINGS)
    }

    return new BehaviorSubject({...DEFAULT_SETTINGS, ...settings})
  }
}
