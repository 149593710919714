import {inject, Injectable, InjectionToken} from "@angular/core";
import {BehaviorSubject, from, Observable, of} from "rxjs";
import {map, tap} from "rxjs/operators";

import {Playlist, PlaylistTree} from "../../contracts/playlist";
import {SupabaseClient} from "packages/supabase/SupabaseClient";
import {PlaylistTreeBuilder} from "../playlist/playlist-tree.builder";

@Injectable()
export class PlaylistProvider {
  private supabase = inject(SupabaseClient).getClient
  private playlistTreeBuilder = inject(PlaylistTreeBuilder)

  cache$ = new BehaviorSubject<PlaylistTree | null>(null);

  public loadById(playlist_id: number | null): Observable<PlaylistTree | null> {
    if (playlist_id === null) {
      return of(null);
    }

    if (this.cache$.value !== null) {
      return of(this.cache$.value).pipe()
    }

    return from(
      this.supabase.rpc('get_playlist_tree', {playlist_id}),
    ).pipe(
      map(res => {
        return res.data as Playlist[]
      }),
      map(res => this.playlistTreeBuilder.build(res)),
      tap(data => {
        this.cache$.next(data)
      })
    )
  }

  // public loadByProfileId(profileId: number): Observable<Playlist> {
  //   return from(
  //     this.supabase.rpc('get_playlist_tree', {profileId: profileId}),
  //   ).pipe(
  //     map(res => {
  //       return res.data as Playlist
  //     })
  //   )
  // }
}
