import {inject, Injectable, InjectionToken, Provider} from "@angular/core";
import {combineLatestWith, from, Observable, of, switchMap} from "rxjs";
import {map, tap} from "rxjs/operators";

import {SupabaseClient} from "packages/supabase/SupabaseClient";
import {Codiew, CodiewMetadata, CreateCodiewRequest} from "contracts/codiew";
import {ServerFilter} from "packages/server/types";
import {HttpClient} from "@angular/common/http";
import {environment} from "environments/environment";
import {PLAYLIST_PROVIDER_PROVIDER} from "./di";

interface CodiewProvider {
  createRecord(data: CreateCodiewRequest): Observable<Codiew>

  loadByShareLink(link: string, finished: boolean): Observable<Codiew>

  loadById(id: number, finished: boolean): Observable<Codiew>

  loadAllByProfileId(profileID: number, filter: ServerFilter): Observable<Codiew[]>

  update(id: number, data: Partial<Codiew>): Observable<Codiew>

  remove(id: number): Observable<unknown>
}

export const ROOT_CODIEW_PROVIDER = new InjectionToken<CodiewProvider>('CodiewProvider')

@Injectable()
export class SupabaseCodiewProvider implements CodiewProvider {
  private supabase = inject(SupabaseClient).getClient
  private http = inject(HttpClient)
  private playlistProvider = inject(PLAYLIST_PROVIDER_PROVIDER)

  public createRecord(data: CreateCodiewRequest): Observable<Codiew> {
    return from(
      this.supabase
        .from('records')
        .upsert(data)
        .select('id, name, status, singleFile, shareStatus, description, template_id, shareLink, meta, media, user:profileId(id, name, email, avatar), createdAt, playlist:playlist_id(id, name)')
        .single()
    ).pipe(
      tap((res) => console.log("call supa record", res)),
      map(res => {
        // @ts-ignore
        return res.data as Codiew
      }),
    )
  }

  loadByShareLink(link: string, finished: boolean): Observable<Codiew> {
    return from(
      this.supabase
        .from('records')
        .select('id, name, status, singleFile, shareStatus, codiew_type, description, template_id, shareLink, meta, media, user:profileId(id, name, email, avatar), createdAt, playlist:playlist_id(id, name)')
        .eq('shareLink', link)
        .single()
    ).pipe(
      map(res => {
        // @ts-ignore
        return res.data as Codiew
      }),

      switchMap(codiew => {
        return this.getMeta(String(codiew.id), finished).pipe(
          map((meta) => {
            codiew.meta = meta
            return codiew
          }),
        )
      }),

    )
  }


  public loadById(id: number, finished = false): Observable<Codiew> {
    return from(
      this.supabase
        .from('records')
        .select('id, name, status, singleFile, shareStatus, codiew_type, description, template_id, shareLink, meta, media, user:profileId(id, name, email, avatar), createdAt, playlist:playlist_id(id, name)')
        .eq('id', id)
        .single()
    ).pipe(
      map(res => {
        // @ts-ignore
        return res.data as Codiew
      }),

      combineLatestWith(this.getMeta(String(id), finished)),

      map(([rec, meta]) => {
        rec.meta = meta
        return rec
      }),
    )
  }

  private getMeta(id: string, finished = false): Observable<CodiewMetadata | undefined> {
    if (!finished) {
      return of(undefined)
    }

    return this.http.get<CodiewMetadata | undefined>(`${environment.mediaGetHost}/${environment.mediaGetBucket}/${String(id)}/meta.json`)
      .pipe(
        map(data => {
          if (!!data && data.runs === undefined) {
            data["runs"] = []
          }

          return data
        })
      )
  }

  public loadAllByProfileId(profileID: number, filter: ServerFilter): Observable<Codiew[]> {
    return from(
      this.supabase
        .from('records')
        .select(
          'id, name, status, singleFile, shareStatus, codiew_type, shareLink, description, template_id, meta, media, user:profileId(id, name, email, avatar), createdAt, playlist:playlist_id(id, name)'
        )
        .eq('profileId', profileID)
        .order(filter.order, {ascending: filter.ascending})
        .range(filter.min, filter.max)
    ).pipe(
      map(res => {
        // @ts-ignore
        return res.data as Codiew[]
      }),
    )

  }

  update(id: number, data: Partial<Codiew>): Observable<Codiew> {
    return from(
      this.supabase
        .from('records')
        .update(data)
        .eq('id', id)
        .select('id, name, status, singleFile, shareStatus, shareLink, codiew_type, description, template_id, media, user:profileId(id, name, email, avatar), createdAt, playlist:playlist_id(id, name)')
        .single()
    )
      .pipe(
        map(res => {
          // @ts-ignore
          return res.data as Codiew
        }),
      )
  }

  remove(id: number): Observable<unknown> {
    return from(
      this.supabase
        .from('records')
        .delete()
        .eq('id', id)
    )
  }

}
