import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {AbstractTuiThemeSwitcher} from '@taiga-ui/cdk';

@Component({
  standalone: true,
  selector: `app-night-theme`,
  styleUrls: [`../styles-night.less`],
  template: ``,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NightComponent extends AbstractTuiThemeSwitcher {

}
