import {ChangeDetectionStrategy, Component, DoCheck, ElementRef, ViewChildren} from "@angular/core";
import {AbstractTuiPortalHostComponent, AbstractTuiPortalService} from "@taiga-ui/cdk";
import {PortalService} from "./portal.service";

@Component({
  standalone: true,
  selector: 'app-portals',
  templateUrl: './portal-host.template.html',
  styleUrls: ['./portal-host.style.less'],
  providers: [{provide: AbstractTuiPortalService, useExisting: PortalService}],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalHost extends AbstractTuiPortalHostComponent {

}
