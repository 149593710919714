import {TuiCountryIsoCode} from "@taiga-ui/i18n";

export type Language = 'en' | 'es' | 'ru'

export const DEFAULT_LANGUAGE: Language = 'en'

export const SUPPORTED_LANGUAGES: Language[] = [
  'en',
  'es',
  // 'ru',
]

export const FLAG_LANGUAGE_MAP = new Map<Language, TuiCountryIsoCode>([
  ['en', TuiCountryIsoCode.US],
  ['es', TuiCountryIsoCode.ES],
  // ['ru', TuiCountryIsoCode.RU],
])

export const NAME_LANGUAGE_MAP = new Map<Language, string>([
  ['en', 'English'],
  ['es', 'Spanish'],
  // ['ru', 'Russian'],
])
