import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {Observable, of, timer} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

@Injectable()
export class HttpMockInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!environment.mock) {
      return next.handle(request);
    }

    const method = request.method.toLowerCase()

    // Return mocked url if match
    // @ts-ignore
    const URLS = REQUESTS[method]
    if (!URLS) {
      return next.handle(request);
    }

    // Моки для GET и POST
    for (const element of URLS) {
      if (request.url === element.url) {
        // GET берем из мока
        const mock = element.json

        let delayTime = 200 // 200 ms задержка по дефолту
        if ('delay' in element) {
          delayTime = element.delay
        }

        return timer(delayTime).pipe(
          switchMap(() => {
            return of(new HttpResponse({status: 200, body: mock}))
              .pipe(
                // subscribeOn(asyncScheduler),
                tap((res) => {
                  console.info('Mocked response for : ' + request.url, res);
                })
              )
          })
        )
      }
    }


    return next.handle(request);
  }

  private withEnvHost(req: HttpRequest<any>): HttpRequest<any> {
    return req
  }
}

const GET = [
  {
    url: '/api/records/45115/comments/9',
    delay: 1000,
    json: { }
  },
]


const REQUESTS = {
  get: GET,
}

