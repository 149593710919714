import {Injectable} from "@angular/core";
import {WorkerType} from "./types";
import {Observable, of} from "rxjs";

@Injectable()
export class WebWorkerman {
  workers: Worker[] = [];
  workersMap = new Map<WorkerType, Worker>

  register$(): Observable<unknown> {
    this.registerWavDecoderWorker()
    this.registerFfmpegWorker()
    return of(true)
  }

  // private registerWavDecoderWorker() {
  //   if (typeof Worker !== 'undefined') {
  //     const worker = new Worker(new URL('./workers/app.worker', import.meta.url));
  //     worker.onmessage = ({data}) => {
  //       console.log(`page got message: ${data}`);
  //     };
  //
  //     this.workers.push(worker)
  //     this.workersMap.set(WorkerTypes.Test, worker)
  //   } else {
  //     console.log("Error on register TEST worker")
  //   }
  // }

  private registerWavDecoderWorker() {
    if (typeof Worker !== 'undefined') {
      const worker = new Worker(new URL('./workers/wav.worker', import.meta.url));

      this.workers.push(worker)
      this.workersMap.set(WorkerType.WavConverter, worker)
    } else {
      console.log("Error on register WavConverter worker")
    }
  }

  private registerFfmpegWorker() {
    if (typeof Worker !== 'undefined') {
      const worker = new Worker(new URL('./workers/ffmpeg.worker', import.meta.url));

      this.workers.push(worker)
      this.workersMap.set(WorkerType.FfmpegConverter, worker)
    } else {
      console.log("Error on register FfmpegConverter worker")
    }
  }

  get(name: WorkerType): Worker | undefined {
    return this.workersMap.get(name);
  }

  terminate() {
    this.workers.forEach(worker => {
      worker.terminate();
    })
  }

  terminateByType(name: WorkerType) {
    this.workersMap.get(name)?.terminate()
  }
}
