import {TuiSvgInterceptorHandler, TuiSvgOptions} from "@taiga-ui/core/components/svg/svg-options";
import {TuiSafeHtml} from "@taiga-ui/cdk";
import {tuiIconResolverProvider} from "@taiga-ui/experimental";


export const AppSvgInterceptor: TuiSvgInterceptorHandler = (src: TuiSafeHtml, options: TuiSvgOptions): TuiSafeHtml => {
  const isNight = String(src).endsWith('::dark')

  if (isNight && (String(src).startsWith('files::') || String(src).startsWith('other::'))) {
    throw new Error("Codiew: dark not available!")
  }

  if (isNight) {
    src = String(src).replace('::dark', '_dark')
  }

  if (String(src).startsWith('files::')) {
    return `/assets/icons/files/${String(src).replace('files::', '')}.svg`
  }
  if (String(src).startsWith('replayer::')) {
    return `/assets/icons/replayer/${String(src).replace('replayer::', '')}.svg`
  }
  if (String(src).startsWith('general::')) {
    return `/assets/icons/general/${String(src).replace('general::', '')}.svg`
  }
  if (String(src).startsWith('other::')) {
    return `/assets/icons/other/${String(src).replace('other::', '')}.svg`
  }
  if (String(src).startsWith('app::')) {
    return `/assets/icons/app/${String(src).replace('app::', '')}.svg`
  }

  return src
}


export const CodiewIconsProvider = () => {
  return tuiIconResolverProvider(src => {
      const isNight = String(src).endsWith('::dark')

      if (isNight && (String(src).startsWith('files::') || String(src).startsWith('other::'))) {
        throw new Error("Codiew: dark not available!")
      }

      if (isNight) {
        src = String(src).replace('::dark', '_dark')
      }

      if (String(src).startsWith('files::')) {
        return `/assets/icons/files/${String(src).replace('files::', '')}.svg`
      }
      if (String(src).startsWith('replayer::')) {
        return `/assets/icons/replayer/${String(src).replace('replayer::', '')}.svg`
      }
      if (String(src).startsWith('general::')) {
        return `/assets/icons/general/${String(src).replace('general::', '')}.svg`
      }
      if (String(src).startsWith('other::')) {
        return `/assets/icons/other/${String(src).replace('other::', '')}.svg`
      }
      if (String(src).startsWith('app::')) {
        return `/assets/icons/app/${String(src).replace('app::', '')}.svg`
      }

      return src
    }
  )
}
