import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Template} from "./type";
import {map, shareReplay, tap} from "rxjs/operators";

@Injectable()
export class TemplateProvider {
  httpClient = inject(HttpClient)

  all() {
    return this.httpClient.get<Template[]>("/assets/templates/data.json")
      .pipe(
        shareReplay(1)
      )
  }

  getById(id?: number) {
    return this.all()
      .pipe(
        map(data => {
          return data.find(datum => datum.id === id)
        }),
      )
  }
}
