import {EmbeddedViewRef, Injectable, TemplateRef} from '@angular/core';
import {AbstractTuiPortalService} from '@taiga-ui/cdk';

@Injectable({
  providedIn: 'root',
})
export class PortalService extends AbstractTuiPortalService {
  els: EmbeddedViewRef<any>[] = []

  addTemplate<C>(templateRef: TemplateRef<C>, context?: C): EmbeddedViewRef<C> {
    const el =  super.addTemplate(templateRef, context);

    this.els.push(el)

    return el
  }
}
