import {InjectionToken, Provider} from "@angular/core";
import {EventDispatcher, IEventDispatcher} from "packages/event-bus/event.bus";

export const ROOT_EVENT_DISPATCHER = new InjectionToken<IEventDispatcher>('ROOT_EVENT_DISPATCHER')

export const provideRootEventDispatcher: () => Provider = () => {
  return {
    provide: ROOT_EVENT_DISPATCHER,
    useFactory: () => new EventDispatcher(),
  }
}
