import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {SupabaseClient} from "packages/supabase/SupabaseClient";
import {Language} from "app/i18n/types";
import {Translator} from "app/i18n/translator";
import {Passport} from "./authenticator";

export type Profile = {
  id: number
  userId: string
  name: string | null
  email: string
  avatar: string
  roles: Array<string>
  lang?: Language
}


@Injectable(
  {providedIn: 'root'}
)
export class Profiler {
  private translator: Translator = inject(Translator)

  private supabase = inject(SupabaseClient).getClient

  private _profile$ = new BehaviorSubject<Profile | null>(null)

  constructor() {

  }


  profile(): Observable<Profile | null> {
    return this._profile$
  }

  public enrichProfile(userId: string): Observable<Profile> {
    return from(
      this.supabase
        .from('profiles')
        .select(`id, userId, name, email, avatar, lang, roles:profile_roles(roles)`)
        .eq('userId', userId)
        .single()
    )
      .pipe(
        // @ts-ignore
        map(res => res.data as Profile),
        tap(profile => {
          if (profile) {
            this._profile$.next(profile)
            profile.lang && this.translator.changeLang(profile.lang, true)
          }
        }),
      )
  }


  updateProfile(id: number, partialProfile: Partial<Profile>): Observable<void> {
    return from(
      this.supabase
        .from('profiles')
        .update(partialProfile)
        .eq('id', id)
        .select(`id, userId, name, email, lang, avatar, roles:profile_roles(roles)`)
    )
      .pipe(
        tap((res) => {
          if (res.data) {
            // @ts-ignore
            const prof = res.data as Profile
            this._profile$.next(prof)
            prof.lang && this.translator.changeLang(prof.lang, true)
          }
        }),
        map(() => undefined)
      )
  }


}
