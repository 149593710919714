import {FeatureFlagKeys} from "./type";
import {FeatureFlagController} from "./feature-flag.controller";
import {inject} from "@angular/core";

export const featureFlagGuard = (feature: FeatureFlagKeys) => {
  return () => {
    const featureFlagService = inject(FeatureFlagController)
    return featureFlagService.supports(feature)
  }
}
