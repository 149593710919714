import {inject, Injectable} from '@angular/core';

import {delayWhen, from, Observable, of, switchMap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import Dexie, {Table} from 'dexie';

import {CodiewEvents} from 'contracts/codiew';
import {fileEventWithTime} from 'codiew/replay/replayer/types';
import {RangedPatch} from 'codiew/studio/patch/patch';
import {environment} from "environments/environment";


export interface RecordEventsCacheSettings {
  edit: boolean;
}

export interface ICodiewChunksProvider {
  prepare(record: number, nums: number[], checkCache: boolean): Observable<void>;

  loadData(record: number, part: number, checkCache: boolean): Observable<CodiewEvents>;

  updateStoredEvents(event: StoredEvents, record: number, chunk: number): Observable<void>

  loadPatches(record: number): Observable<RangedPatch[]>

  savePatches(patches: RangedPatch[], record: number): Observable<void>
}

// Пачка событий в один момент времени
export interface StoredEvents {
  timestamp: number,
  events: fileEventWithTime[]
}

@Injectable({
  providedIn: 'any',
})
export class CodiewChunkProvider implements ICodiewChunksProvider {
  http = inject(HttpClient);
  db!: Dexie;

  createdTablesMap: Map<number, true> = new Map();

  prepare(record: number, tablesNums: number[], checkCache: boolean): Observable<any> {
    if (!checkCache) {
      return of(true)
    }

    this.db = new Dexie('edit_' + (String(record)));

    return of(1).pipe(
      switchMap(() => {
        if (this.createdTablesMap.get(record)) {
          return of(1);
        }
        return from(this.createDBIfNotExists(record, tablesNums));
      }),
      tap(() => this.createdTablesMap.set(record, true)),
    );
  }

  loadData(record: number, part: number, withCache = false): Observable<CodiewEvents> {

    const tableName = `chunk_${part}`;

    return from(this.isTableExists(tableName, withCache))
      .pipe(
        switchMap((exists) => {
          if (!withCache) {
            return this.request(record, part, withCache, tableName);
          }
          if (!exists) {
            throw new Error(`Table ${tableName} not exists, may be you forgot to use prepare() method first?`);
          }

          return from(this.db.open())
            .pipe(
              switchMap(() => from(this.db.table<StoredEvents>(tableName).toArray())),
              switchMap((cached): Observable<CodiewEvents> => {
                if (!!cached && cached.length > 0) {
                  const res: fileEventWithTime[] = [];

                  cached.forEach(se => {
                    res.push(...se.events);
                  });

                  return of({
                    serialNumber: part,
                    events: res,
                  });
                }

                return this.request(record, part, withCache, tableName);
              }),
            );
        }),
      );
  }

  private request(record: number, part: number, withCache: boolean, tableName: string) {
    return this.http.get<CodiewEvents>(`${environment.mediaGetHost}/${environment.mediaGetBucket}/${String(record)}/events_${part}.json`)
      .pipe(
        delayWhen(data =>  withCache
          ? this.cacheData(data, tableName)
          : of(true)
        ),
      );
  }

  private cacheData(data: CodiewEvents, tableName: string): Observable<void> {
    const storeEvents: StoredEvents[] = [];
    let lastTimestamp = 0;
    let buf: fileEventWithTime[] = [];

    data.events.forEach(e => {
      if (e.tstm === lastTimestamp) {
        buf.push(e);
        lastTimestamp = e.tstm;
        return;
      }

      if (lastTimestamp === 0) {
        lastTimestamp = e.tstm;
        buf = [e];
        return;
      }
      storeEvents.push({
        timestamp: lastTimestamp,
        events: buf,
      });

      lastTimestamp = e.tstm;
      buf = [e];
    });
    storeEvents.push({
      timestamp: lastTimestamp,
      events: buf,
    });

    return from(this.db.table(tableName).bulkAdd(storeEvents))
      .pipe(map(() => {}))
  }


  updateStoredEvents(event: StoredEvents, record: number, part: number): Observable<void> {
    const tableName = `chunk_${part}`;

    return from(this.isTableExists(tableName))
      .pipe(
        // check table exists
        tap((exists) => {
          if (!exists) {
            throw new Error(`Table ${tableName} not exists on save event: ${event}`)
          }
        }),
        // check element exists
        switchMap(_ => {
          return from(this.db.table(tableName).get(event.timestamp))
            .pipe(tap(ev => {
              if (!ev) {
                throw new Error(`Not found events set on timestamp: ${event.timestamp}`)
              }
            }))
        }),
        // update
        switchMap(() => {
          return from(this.db.table(tableName).update(event.timestamp, event));
        }),
        map(() => undefined)
      )
  }

  savePatches(patches: RangedPatch[], record: number): Observable<void> {
    const tableName = `patches`;

    return from(this.isTableExists(tableName))
      .pipe(
        tap(exists => {
          if (!exists) {
            throw new Error(`Patch table for record ${record} not exists`)
          }
        }),
        switchMap(() => {
          return from(this.db.table(tableName).put(patches))
        }),
        map(() => {})
      )
  }

  loadPatches(record: number): Observable<RangedPatch[]> {
    const tableName = `patches`;

    return from(this.isTableExists(tableName))
      .pipe(
        tap(exists => {
          if (!exists) {
            throw new Error(`Patch table for record ${record} not exists`)
          }
        }),
        switchMap(() => {
          return from(this.db.table(tableName).toArray())
        })
      )
  }

  private async createDBIfNotExists(record: number, tablesNums: number[]) {
    try {
      await this.db.open();
    } catch (e) {
      const schema: {
        [tableName: string]: string | null;
      } = {};

      // Chunks
      tablesNums.forEach(i => {
        const tableName = `chunk_${i}`;
        schema[tableName] = 'timestamp';
      });

      schema['patches'] = 'id'

      this.db.version(1).stores(schema);

    }
  }

  async isTableExists(table: string, useCache = true): Promise<boolean> {
    if (!useCache) {
      return true
    }

    let exists = false;
    try {
      await this.db.open(); // Открытие соединения с базой данных
      exists = !!this.db.tables.find(t => t.name === table);
    } finally {
      // this.db.close();
    }

    return exists;
  }
}

class RecordDB extends Dexie {
  settings!: Table<any, string>;

  constructor(databaseName: string, prefix: string = '') {
    super(prefix + databaseName);

    this.version(3).stores({
      settings: 'id',
    });
  }
}




