import {Injectable} from "@angular/core";
import {Playlist, PlaylistTree} from "../../contracts/playlist";


@Injectable()
export class PlaylistTreeBuilder {
  build(playlists: Playlist[]): PlaylistTree | null {
    const map = new Map<number, PlaylistTree>();
    const roots: PlaylistTree[] = [];

    playlists.forEach((playlist) => {
      const node: PlaylistTree = {
        id: playlist.id,
        name: playlist.name,
        parent_id: playlist.parent_id,
        codiews: playlist.codiews,
        level: 0,
        codiewsCount: playlist.codiews ? playlist.codiews.length : 0
      };
      map.set(playlist.id, node);

      if (playlist.parent_id === null) {
        roots.push(node);
      }
    });

    playlists.forEach((playlist) => {
      const parent_id = playlist.parent_id;
      if (parent_id !== null) {
        const parentNode = map.get(parent_id);
        const childNode = map.get(playlist.id);

        if (parentNode && childNode) {
          parentNode.children = parentNode.children || [];
          parentNode.children.push(childNode);

          childNode.level = parentNode.level + 1;
        }
      }
    });

    const calculateCodiewsCount = (node: PlaylistTree): number => {
      if (!node.children || node.children.length === 0) {
        return node.codiewsCount;
      }

      node.children.forEach((ch) => {
        node.codiewsCount += calculateCodiewsCount(ch);
      });

      return node.codiewsCount;
    }

    const root = roots[0] || null

    if (root !== null) {
      calculateCodiewsCount(root);
    }

    return root
  }
}
