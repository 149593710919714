import {APP_INITIALIZER, inject, InjectionToken, Provider} from "@angular/core";
import {Observable} from "rxjs";
import {FeatureFlagController} from "./feature-flag.controller";

export const provideFeatureFlags = (): Provider => ({
  provide: APP_INITIALIZER,
  useFactory: (): () => Observable<any> => {
    const featureFlagService = inject(FeatureFlagController);
    return () => featureFlagService.loadFlags();
  },
  deps: [],
  multi: true,
})

export const IS_DEMO = new InjectionToken<boolean>("IS_DEMO");
